import React from 'react';
import Home from './Components/Home/index';


const App = () => {
  return (
    <>
        <Home />
        <div>
          SUCCESS
        </div>
    </>
  )
}

export default App